<template>
	<div style="display:block" class="layer">
		<div class="layer__dim"></div>
		<div class="layer__full">
			<div class="layer__fullbox">
				<div class="layer__fullheader">
					<nav class="nav">
						<div class="nav__wrap">
							<a @click="goBack" class="nav__btn nav__btn--prev">
								<i class="icon icon-close">{{t('10675')}}</i>
							</a>
							<p class="nav__item">
								<span class="nav__text">
									{{t('10676')}}
								</span>
							</p>
							<a href="#" class="nav__btn">
							</a>
						</div>
					</nav>
				</div>
                <div class="layer__fullbody" style="height: 100%">
                    <div class="map__wrap" style="height: 100%">
                        <img :src="imgUrl" />
                    </div>
                </div>
			</div>
		</div>
	</div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 

export default {
	setup() {
        const router = useRouter();
        const route = useRoute();
		const { t }= useI18n() 
		return {
            imgUrl: route.query.url,
			t,  //번역필수 모듈
		  i18n, 
			goBack() {
                router.back();
            },
		}
	}
}
</script>